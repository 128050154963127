// Projects.js
import React from "react";
import projects from "../data";

const Projects = () => {
  return (
    <div className="projects">
      {projects.map((project, index) => (
        <div key={index} className="project">
          <h2 className="project__title">{project.title}</h2>
          <div className="project__images">
            {project.images.map((image, idx) => (
              <img
                key={idx}
                src={image}
                alt={`${project.title} ${idx + 1}`}
                className="project__image"
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Projects;
