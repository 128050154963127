// projects.js

// ATF Jarman Park 1414 Project
import img1 from "./img/bute/ATF Jarman Park 1414 Project/1723571328273.jpg";
import img2 from "./img/bute/ATF Jarman Park 1414 Project/1723571328345.jpeg";
import img3 from "./img/bute/ATF Jarman Park 1414 Project/1723571336245.jpeg";
import img5 from "./img/bute/ATF Jarman Park 1414 Project/1723571336571.jpeg";
import img7 from "./img/bute/ATF Jarman Park 1414 Project/1723571336676.jpeg";
import img8 from "./img/bute/ATF Jarman Park 1414 Project/1723571336803.jpeg";
import img11 from "./img/bute/ATF Jarman Park 1414 Project/1723571458770.jpeg";
import img13 from "./img/bute/ATF Jarman Park 1414 Project/1723571468390.jpeg";
import img17 from "./img/bute/ATF Jarman Park 1414 Project/1723571469947.jpeg";
import img19 from "./img/bute/ATF Jarman Park 1414 Project/1723571470765.jpeg";
import img20 from "./img/bute/ATF Jarman Park 1414 Project/1723571470787.jpeg";

// Builders Depot New Southgate
import img21 from "./img/bute/Builders Depot New Southgate/1710802397572.jpeg";
import img22 from "./img/bute/Builders Depot New Southgate/1710802398014.jpeg";
import img23 from "./img/bute/Builders Depot New Southgate/1710802398813.jpeg";
import img24 from "./img/bute/Builders Depot New Southgate/1710802400230.jpeg";
import img25 from "./img/bute/Builders Depot New Southgate/1710802400319.jpeg";
import img26 from "./img/bute/Builders Depot New Southgate/1710802400454.jpeg";

// Eurovia - Cherry Three Lane Project
import img27 from "./img/bute/Eurovia - Cherry Three Lane Project/IMG-20241103-WA0071.jpg";
import img28 from "./img/bute/Eurovia - Cherry Three Lane Project/IMG-20241103-WA0072.jpg";
import img29 from "./img/bute/Eurovia - Cherry Three Lane Project/IMG-20241103-WA0073.jpg";
import img30 from "./img/bute/Eurovia - Cherry Three Lane Project/IMG-20241103-WA0074.jpg";
import img31 from "./img/bute/Eurovia - Cherry Three Lane Project/IMG-20241103-WA0075.jpg";
import img32 from "./img/bute/Eurovia - Cherry Three Lane Project/IMG-20241103-WA0076.jpg";
import img33 from "./img/bute/Eurovia - Cherry Three Lane Project/IMG-20241103-WA0077.jpg";
import img34 from "./img/bute/Eurovia - Cherry Three Lane Project/IMG-20241103-WA0080.jpg";
import img35 from "./img/bute/Eurovia - Cherry Three Lane Project/IMG-20241103-WA0081.jpg";
import img36 from "./img/bute/Eurovia - Cherry Three Lane Project/IMG-20241103-WA0082.jpg";
import img37 from "./img/bute/Eurovia - Cherry Three Lane Project/IMG-20241103-WA0083.jpg";
import img38 from "./img/bute/Eurovia - Cherry Three Lane Project/IMG-20241103-WA0084.jpg";

// Eurovia - High Street, Tring Project
import img43 from "./img/bute/Eurovia - High Street, Tring Project/IMG-20241103-WA0013.jpg";
import img44 from "./img/bute/Eurovia - High Street, Tring Project/IMG-20241103-WA0014.jpg";
import img45 from "./img/bute/Eurovia - High Street, Tring Project/IMG-20241103-WA0015.jpg";
import img46 from "./img/bute/Eurovia - High Street, Tring Project/IMG-20241103-WA0016.jpg";
import img47 from "./img/bute/Eurovia - High Street, Tring Project/IMG-20241103-WA0017.jpg";
import img48 from "./img/bute/Eurovia - High Street, Tring Project/IMG-20241103-WA0018.jpg";
import img49 from "./img/bute/Eurovia - High Street, Tring Project/IMG-20241103-WA0019.jpg";
import img50 from "./img/bute/Eurovia - High Street, Tring Project/IMG-20241103-WA0020.jpg";
import img51 from "./img/bute/Eurovia - High Street, Tring Project/IMG-20241103-WA0021.jpg";
import img52 from "./img/bute/Eurovia - High Street, Tring Project/IMG-20241103-WA0022.jpg";
import img53 from "./img/bute/Eurovia - High Street, Tring Project/IMG-20241103-WA0023.jpg";
import img54 from "./img/bute/Eurovia - High Street, Tring Project/IMG-20241103-WA0024.jpg";
import img55 from "./img/bute/Eurovia - High Street, Tring Project/IMG-20241103-WA0025.jpg";
import img56 from "./img/bute/Eurovia - High Street, Tring Project/IMG-20241103-WA0026.jpg";
import img57 from "./img/bute/Eurovia - High Street, Tring Project/IMG-20241103-WA0027.jpg";
import img58 from "./img/bute/Eurovia - High Street, Tring Project/IMG-20241103-WA0028.jpg";
import img59 from "./img/bute/Eurovia - High Street, Tring Project/IMG-20241103-WA0029.jpg";
import img60 from "./img/bute/Eurovia - High Street, Tring Project/IMG-20241103-WA0030.jpg";
import img61 from "./img/bute/Eurovia - High Street, Tring Project/IMG-20241103-WA0031.jpg";
import img62 from "./img/bute/Eurovia - High Street, Tring Project/IMG-20241103-WA0032.jpg";
import img63 from "./img/bute/Eurovia - High Street, Tring Project/IMG-20241103-WA0033.jpg";
import img64 from "./img/bute/Eurovia - High Street, Tring Project/IMG-20241103-WA0034.jpg";
import img65 from "./img/bute/Eurovia - High Street, Tring Project/IMG-20241103-WA0035.jpg";
import img66 from "./img/bute/Eurovia - High Street, Tring Project/IMG-20241103-WA0036.jpg";
import img67 from "./img/bute/Eurovia - High Street, Tring Project/IMG-20241103-WA0037.jpg";
import img68 from "./img/bute/Eurovia - High Street, Tring Project/IMG-20241103-WA0038.jpg";
import img69 from "./img/bute/Eurovia - High Street, Tring Project/IMG-20241103-WA0039.jpg";
import img70 from "./img/bute/Eurovia - High Street, Tring Project/IMG-20241103-WA0040.jpg";
import img71 from "./img/bute/Eurovia - High Street, Tring Project/IMG-20241103-WA0041.jpg";

// Ringways - ITP Bearton Road, Hitchin Project

import img200 from "./img/bute/Ringways - ITP Bearton Road, Hitchin Project/1720197518704.jpg";
import img201 from "./img/bute/Ringways - ITP Bearton Road, Hitchin Project/1720197519738.jpg";
import img202 from "./img/bute/Ringways - ITP Bearton Road, Hitchin Project/1720197520339.jpg";
import img203 from "./img/bute/Ringways - ITP Bearton Road, Hitchin Project/1720197520375.jpg";
import img204 from "./img/bute/Ringways - ITP Bearton Road, Hitchin Project/1720197520672.jpeg";
import img205 from "./img/bute/Ringways - ITP Bearton Road, Hitchin Project/1720197520691.jpeg";
import img206 from "./img/bute/Ringways - ITP Bearton Road, Hitchin Project/1720197520704.jpeg";
import img207 from "./img/bute/Ringways - ITP Bearton Road, Hitchin Project/1730654451428.jpg";
import img208 from "./img/bute/Ringways - ITP Bearton Road, Hitchin Project/1730654452003.jpeg";
import img209 from "./img/bute/Ringways - ITP Bearton Road, Hitchin Project/1730654452043.jpeg";
import img210 from "./img/bute/Ringways - ITP Bearton Road, Hitchin Project/1730654452169.jpeg";
import img211 from "./img/bute/Ringways - ITP Bearton Road, Hitchin Project/1730654452633.jpeg";

//Ringways - ATF Marlbrough St. Albans Project
import img159 from "./img/bute/Ringways - ATF Marlborough St. Albans Project/1720197465183.jpg";
import img160 from "./img/bute/Ringways - ATF Marlborough St. Albans Project/1720197465937.jpg";
import img161 from "./img/bute/Ringways - ATF Marlborough St. Albans Project/1720197466152.jpg";
import img162 from "./img/bute/Ringways - ATF Marlborough St. Albans Project/1720197466401.jpg";
import img163 from "./img/bute/Ringways - ATF Marlborough St. Albans Project/1723571260598.jpg";
import img164 from "./img/bute/Ringways - ATF Marlborough St. Albans Project/1723571261001.jpg";
import img165 from "./img/bute/Ringways - ATF Marlborough St. Albans Project/1723571261747.jpg";
import img166 from "./img/bute/Ringways - ATF Marlborough St. Albans Project/1730653944257.jpeg";
import img167 from "./img/bute/Ringways - ATF Marlborough St. Albans Project/1730653944427.jpg";
import img168 from "./img/bute/Ringways - ATF Marlborough St. Albans Project/1730653944503.jpeg";
import img169 from "./img/bute/Ringways - ATF Marlborough St. Albans Project/1730653945043.jpeg";
import img170 from "./img/bute/Ringways - ATF Marlborough St. Albans Project/1730653945169.jpg";
import img171 from "./img/bute/Ringways - ATF Marlborough St. Albans Project/1730653945257.jpeg";
import img172 from "./img/bute/Ringways - ATF Marlborough St. Albans Project/1730653944427.jpg";
import img173 from "./img/bute/Ringways - ATF Marlborough St. Albans Project/1730653944503.jpeg";
import img174 from "./img/bute/Ringways - ATF Marlborough St. Albans Project/1730653945043.jpeg";
import img175 from "./img/bute/Ringways - ATF Marlborough St. Albans Project/1730653945169.jpg";

//CAT 3 Washington Ave Hemel Hampstead - Ringway Project

import img300 from "./img/bute/CAT 3 Washington Ave Hemel Hampstead - Ringway Project/1.jpg";
import img301 from "./img/bute/CAT 3 Washington Ave Hemel Hampstead - Ringway Project/2.jpg";
import img302 from "./img/bute/CAT 3 Washington Ave Hemel Hampstead - Ringway Project/3.jpg";
import img303 from "./img/bute/CAT 3 Washington Ave Hemel Hampstead - Ringway Project/4.jpg";
import img304 from "./img/bute/CAT 3 Washington Ave Hemel Hampstead - Ringway Project/5.jpg";
import img305 from "./img/bute/CAT 3 Washington Ave Hemel Hampstead - Ringway Project/6.jpg";
import img306 from "./img/bute/CAT 3 Washington Ave Hemel Hampstead - Ringway Project/7.jpg";
import img307 from "./img/bute/CAT 3 Washington Ave Hemel Hampstead - Ringway Project/8.jpg";
import img308 from "./img/bute/CAT 3 Washington Ave Hemel Hampstead - Ringway Project/9.jpg";
import img309 from "./img/bute/CAT 3 Washington Ave Hemel Hampstead - Ringway Project/10.jpg";
import img310 from "./img/bute/CAT 3 Washington Ave Hemel Hampstead - Ringway Project/11.jpg";

// Stevenage Project
import img145 from "./img/bute/Stevenage Project/1701102891727.jpeg";
import img146 from "./img/bute/Stevenage Project/1701102893885.jpeg";
import img147 from "./img/bute/Stevenage Project/1701102894172.jpeg";
import img148 from "./img/bute/Stevenage Project/1701102894629.jpeg";
import img149 from "./img/bute/Stevenage Project/1701102894731.jpeg";
import img150 from "./img/bute/Stevenage Project/1701102894756.jpeg";
import img151 from "./img/bute/Stevenage Project/1701103169113.jpg";
import img152 from "./img/bute/Stevenage Project/1701103191576.jpg";
import img153 from "./img/bute/Stevenage Project/1701103188411.jpg";
import img154 from "./img/bute/Stevenage Project/1701103191506.jpg";
import img155 from "./img/bute/Stevenage Project/1701103193976.jpeg";
import img156 from "./img/bute/Stevenage Project/1701103194355.jpg";
import img157 from "./img/bute/Stevenage Project/1701103194660.jpeg";
import img158 from "./img/bute/Stevenage Project/1701103194707.jpeg";

// Welwyn Garden City
import img72 from "./img/bute/Welwyn Garden City/1701100983253.jpg";
import img76 from "./img/bute/Welwyn Garden City/1701100987510.jpg";
import img77 from "./img/bute/Welwyn Garden City/1701100988539.jpg";
import img79 from "./img/bute/Welwyn Garden City/1701100990015.jpg";
import img80 from "./img/bute/Welwyn Garden City/1701100990273.jpg";
import img81 from "./img/bute/Welwyn Garden City/1701101456088.jpg";
import img82 from "./img/bute/Welwyn Garden City/1701101458976.jpg";
import img83 from "./img/bute/Welwyn Garden City/1701101459003.jpg";
import img84 from "./img/bute/Welwyn Garden City/1701101459278.jpg";
import img85 from "./img/bute/Welwyn Garden City/1701101459337.jpg";
import img86 from "./img/bute/Welwyn Garden City/1701101649402.jpg";
import img87 from "./img/bute/Welwyn Garden City/1701101650192.jpg";
import img88 from "./img/bute/Welwyn Garden City/1701101652296.jpg";
import img89 from "./img/bute/Welwyn Garden City/1701101652510.jpg";
import img90 from "./img/bute/Welwyn Garden City/1701101652521.jpg";

// Work Gallery 1
import img91 from "./img/bute/Work Gallery 1/1701102071600.jpg";
import img92 from "./img/bute/Work Gallery 1/1701102086895.jpg";
import img93 from "./img/bute/Work Gallery 1/1701102088546.jpg";
import img94 from "./img/bute/Work Gallery 1/1701102088554.jpg";
import img95 from "./img/bute/Work Gallery 1/1701102089686.jpg";
import img96 from "./img/bute/Work Gallery 1/1701102092696.jpg";
import img97 from "./img/bute/Work Gallery 1/1701102093134.jpg";
import img107 from "./img/bute/Work Gallery 1/1701102560428.jpg";
import img108 from "./img/bute/Work Gallery 1/1701102561921.jpg";

// Work Gallery 2
import img120 from "./img/bute/Work Gallery 2/1701102887653.jpg";
import img121 from "./img/bute/Work Gallery 2/1701102887737.jpg";
import img122 from "./img/bute/Work Gallery 2/1701102894756.jpg";
import img123 from "./img/bute/Work Gallery 2/1701103517197.jpg";
import img124 from "./img/bute/Work Gallery 2/1701103519154.jpg";
import img125 from "./img/bute/Work Gallery 2/1701103524027.jpg";
import img126 from "./img/bute/Work Gallery 2/1701103525331.jpg";
import img127 from "./img/bute/Work Gallery 2/1701103543483.jpg";
import img129 from "./img/bute/Work Gallery 2/1701103704873.jpg";
import img130 from "./img/bute/Work Gallery 2/1701103706105.jpg";
import img131 from "./img/bute/Work Gallery 2/1701103707510.jpg";
import img132 from "./img/bute/Work Gallery 2/1701103712466.jpg";
import img133 from "./img/bute/Work Gallery 2/1701103880769.jpg";
import img134 from "./img/bute/Work Gallery 2/1701103884650.jpg";
import img137 from "./img/bute/Work Gallery 2/1701103888389.jpg";
import img139 from "./img/bute/Work Gallery 2/1701104039152.jpg";
import img140 from "./img/bute/Work Gallery 2/1701104042709.jpg";
import img141 from "./img/bute/Work Gallery 2/1701104043689.jpg";
import img142 from "./img/bute/Work Gallery 2/1701104043989.jpg";
import img143 from "./img/bute/Work Gallery 2/1701104192843.jpg";

const projects = [
  {
    title: "ATF Jarman Park 1414 Project",
    images: [
      img1,
      img2,
      img3,
      img5,
      img7,
      img8,
      img11,
      img13,
      img17,
      img19,
      img20,
    ],
  },
  {
    title: "Builders Depot New Southgate",
    images: [img21, img22, img23, img24, img25, img26],
  },
  {
    title: "Eurovia - Cherry Three Lane Project",
    images: [
      img27,
      img28,
      img29,
      img30,
      img31,
      img32,
      img33,
      img34,
      img35,
      img36,
      img37,
      img38,
    ],
  },
  {
    title: "Eurovia - High Street, Tring Project",
    images: [
      img43,
      img44,
      img45,
      img46,
      img47,
      img48,
      img49,
      img50,
      img51,
      img52,
      img53,
      img54,
      img55,
      img56,
      img57,
      img58,
      img59,
      img60,
      img61,
      img62,
      img63,
      img64,
      img65,
      img66,
      img67,
      img68,
      img69,
      img70,
      img71,
    ],
  },
  {
    title: "Ringways - ITP Bearton Road, Hitchin Project",
    images: [
      img200,
      img201,
      img202,
      img203,
      img204,
      img205,
      img206,
      img207,
      img208,
      img209,
      img210,
      img211,
    ],
  },
  {
    title: "Ringways - ATF Marlborough St. Albans Project",
    images: [
      img159,
      img160,
      img161,
      img162,
      img163,
      img164,
      img165,
      img166,
      img167,
      img168,
      img169,
      img170,
      img171,
      img172,
      img173,
      img174,
      img175,
    ],
  },
  {
    title: "CAT 3 Washington Ave Hemel Hampstead - Ringway Project",
    images: [
      img300,
      img301,
      img302,
      img303,
      img304,
      img305,
      img306,
      img307,
      img308,
      img309,
      img310,
    ],
  },
  {
    title: "Stevenage Project",
    images: [
      img145,
      img146,
      img147,
      img148,
      img149,
      img150,
      img151,
      img152,
      img153,
      img154,
      img155,
      img156,
      img157,
      img158,
    ],
  },
  {
    title: "Welwyn Garden City",
    images: [
      img72,
      img76,
      img77,
      img79,
      img80,
      img81,
      img82,
      img83,
      img84,
      img85,
      img86,
      img87,
      img88,
      img89,
      img90,
    ],
  },
  {
    title: "Work Gallery 1",
    images: [img91, img92, img93, img94, img95, img96, img97, img107, img108],
  },
  {
    title: "Work Gallery 2",
    images: [
      img120,
      img121,
      img122,
      img123,
      img124,
      img125,
      img126,
      img127,
      img129,
      img130,
      img131,
      img132,
      img133,
      img134,
      img137,
      img139,
      img140,
      img141,
      img142,
      img143,
    ],
  },
];

export default projects;
