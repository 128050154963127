import React from "react";
import { Link } from "react-router-dom";
import wall_2 from "../img/wall_2.png";
import wall_3 from "../img/wall_3.png";
const Card = React.memo(({ heading, content }) => {
  return (
    <div className="service__card">
      <h1 className="service__card__heading">{heading}</h1>
      <p className="service__card__content">{content}</p>
    </div>
  );
});

export default function Service() {
  return (
    <main className="service">
      <h1
        className="heading__primary"
        style={{ textAlign: "center", marginBottom: "1rem", marginTop: "2rem" }}
      >
        why choose us
      </h1>
      <div className="service__container">
        <div className="service__text">
          <Card
            heading="Comprehensive Expertise"
            content="Built on a foundation of expertise, Bute Construction brings a wealth of knowledge and experience to every project we undertake. We offer high-quality services and consider emerging challenges as factors that drive success. With the commitment to excellence, we deliver results that stand the test of time."
          />
          <Card
            heading="Modern & Strategical Approach"
            content="Our forward-thinking mindset allows us to overcome challenges and seize opportunities, ensuring efficient and sustainable solutions for every project. With a strategic vision guiding us, we shape spaces that inspire and endure, setting new standards for excellence in the construction industry."
          />
          <Card
            heading="Quality"
            content="Bute Construction is committed to delivering the highest quality of workmanship and service to our clients. We strive to exceed expectations and build long-term relationships with our clients, partners, and communities. Our team is dedicated to providing the best possible experience for our clients, from the initial consultation to the final walkthrough. We take pride in our work and stand behind it with a 100% satisfaction guarantee."
          />
          <Card
            heading="Cost Effective Solutions"
            content="Our team's expertise in value engineering and smart decision-making ensures that we maximize efficiency without compromising on craftsmanship. With a focus on cost-effectiveness, we build not just structures, but strong foundations for long-term success, providing exceptional value to our clients every step of the way."
          />
        </div>
        <div className="service__wall">
          <img
            src={wall_2}
            alt="wall_photos"
            className="service__wall__photos service__wall__1"
            loading="lazy"
          />
          <img
            src={wall_3}
            alt="wall_photos"
            className="service__wall__photos service__wall__3"
            loading="lazy"
          />
          <Link className="service__link" to="/Works">
            Works
          </Link>
        </div>
      </div>
    </main>
  );
}
